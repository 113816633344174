import './App.css'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

import { ResolutionProvider } from '@graniot/common/context/ResolutionContext'
import { LayersProvider } from '@graniot/common/context/LayersContext'
import { LocationProvider } from '@graniot/common/context/LocationContext'

import { useAuth } from '@graniot/common/context/AuthContext'
import { CompanyProvider } from '@graniot/common/context/CompanyContext'

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'))

const outerTheme = createTheme({
    palette: {
        primary: {
			main: '#232C57',
            light: '#b47cff',
            dark: '#3f1dcb',
            complementary: '#d0ff4d',
            analogous: '#4d77ff',
            triadic: '#ff7c4d',
        },
        secondary: {
            main: '#DB491B',
        },
    },
    typography: {
        fontFamily: ['Roboto', 'Fira Sans'].join(','),
    },
})

function App() {
    const { user } = useAuth()

    return (
        <ThemeProvider theme={outerTheme}>
            <CssBaseline />
            <Router>
                <React.Suspense
                    fallback={
                        <Backdrop open={true}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    }
                >
                    {user ? (
                        <LocationProvider>
                            <LayersProvider>
                                <ResolutionProvider>
                                    <CompanyProvider>
                                        <AuthenticatedApp key={user.username} />
                                    </CompanyProvider>
                                </ResolutionProvider>
                            </LayersProvider>
                        </LocationProvider>
                    ) : (
                        <UnauthenticatedApp />
                    )}
                </React.Suspense>
            </Router>
        </ThemeProvider>
    )
}

export default App
